<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-13 14:17:08
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 09:34:25
 * @Description: 库位转移
 * @FilePath: \src\views\StockManage\MoveExpressStorage.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="库位转移"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <!-- ID缓存 焦点锁这里 -->
    <van-field
      v-model="idCache"
      label="idCache"
      ref="idCache_input_dom"
      name="idCache_input_dom"
      class="id-cache"
      autocomplete="off"
      @blur="idCacheInputBlur"
      placeholder=""
    />

    <!-- 库位编码 -->
    <van-field
      v-model="kst_tag_id"
      label="新库位编码"
      autocomplete="off"
      @click="clickTagIdInput()"
      placeholder="等待单号输入"
    />

    <!-- 快递单号 -->
    <van-field
      v-model="kec_express_id"
      label="快递单号"
      autocomplete="off"
      @click="clickExpressInput()"
      placeholder="等待单号输入"
    />

    <van-cell center title="批量模式">
      <template #value>
        <van-switch v-model="batch" size="24" />
      </template>
    </van-cell>

    <van-cell center title="库位信息查询">
      <template #value>
        <van-switch v-model="get_kst_info" size="24" />
      </template>
    </van-cell>

    <!-- 库位信息显示 -->
    <van-cell-group
      inset
      title="库位信息"
      v-if="kst_info.kst_id !== undefined && get_kst_info"
    >
      <van-cell title="库位状态">
        <template #value>
          <van-tag type="success" v-if="kst_info.isinit">可用</van-tag>
          <van-tag type="success" v-else>未初始化</van-tag>
          <!-- <van-tag type="danger">不可用</van-tag> -->
        </template>
      </van-cell>
      <van-cell title="所属仓库" :value="kst_info.kw_name" />
      <van-cell title="库位所属用户" :value="kst_info.ku_nickname" />
      <van-cell title="库位类型">
        <van-tag type="primary" v-if="kst_info.kst_type == 140010">
          货架存放
        </van-tag>
        <van-tag type="primary" v-else-if="kst_info.kst_type == 140011">
          货架行存
        </van-tag>
        <van-tag type="primary" v-else-if="kst_info.kst_type == 140012">
          散堆存放
        </van-tag>
        <van-tag type="primary" v-else-if="kst_info.kst_type == 140013">
          垛堆存放
        </van-tag>
        <van-tag type="primary" v-else-if="kst_info.kst_type == 140014">
          成组堆码存放
        </van-tag>
        <van-tag type="danger" v-else>未知</van-tag>
      </van-cell>
      <van-cell title="库位导航" :value="kst_info.role" />
    </van-cell-group>
  </div>
</template>

<script>
import { a_moveExpressStorage, getStorageInfoByTagId } from "./api";
import { getCurrentInstance, onMounted, ref, watch } from "vue";
export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const idCache_input_dom = ref(null); //Dom对象
    const batch = ref(false); //批量操作
    const idCache = ref(""); //ID缓存
    const kst_tag_id = ref(""); //库位RFID
    const kec_express_id = ref(""); //快递单号
    const get_kst_info = ref(true); //控制是否查询库位信息
    const kst_info = ref({}); //库位信息

    //获取库位信息
    const getStorageInfo = () => {
      getStorageInfoByTagId({
        kst_tag_id: kst_tag_id.value, //库位ID
      })
        .then((res) => {
          kst_info.value = res.data;
          //   utils.success(res.err_msg, 800);
        })
        .catch((err) => {
          utils.fail(err.err_msg, 800);
        });
    };

    //快递单号输入框点击事件
    const clickExpressInput = () => {
      kec_express_id.value = ""; //清空快递单号
    };

    //库位RFID输入框点击事件
    const clickTagIdInput = () => {
      kst_tag_id.value = ""; //清空快递单号
      kst_info.value = {};
    };

    //监听单号缓存
    watch(
      () => idCache.value,
      () => {
        // 延迟1秒执行
        setTimeout(() => {
          if (idCache.value !== "") {
            if (idCache.value.length >= 6 && idCache.value.length <= 20) {
              let jq = idCache.value.substr(0, 4);
              if (jq === "CNFY") {
                //库位编码
                kst_tag_id.value = idCache.value;

                if (get_kst_info.value) {
                  getStorageInfo();
                }
              } else {
                //快递单号
                kec_express_id.value = idCache.value;
              }
            }
            idCache.value = "";
          }
        }, 100);
      }
    );

    //监听快递单号变化
    watch(
      () => kec_express_id.value,
      () => {
        // 延迟1秒执行
        setTimeout(() => {
          if (kec_express_id.value !== "" && kst_tag_id.value !== "") {
            moveExpressStorage();
          }
        }, 100);
      }
    );

    //监听查询库位信息状态变化
    watch(
      () => get_kst_info.value,
      () => {
        if (get_kst_info.value === false) {
          kst_info.value = {};
        }
      }
    );

    //监听库位RFID变化
    watch(
      () => kst_tag_id.value,
      () => {
        // 延迟1秒执行
        setTimeout(() => {
          if (kec_express_id.value !== "" && kst_tag_id.value !== "") {
            moveExpressStorage();
          }
        }, 100);
      }
    );

    //ID缓存框失焦时触发
    const idCacheInputBlur = () => {
      idCacheInputGainFocus();
    };

    //控制输入框获取焦点
    const idCacheInputGainFocus = () => {
      //找到input的dom对象
      var inpdom =
        idCache_input_dom.value.$el.children[1].children[0].children[0];
      inpdom.setAttribute("readonly", "readonly");
      inpdom.focus();
      setTimeout(() => {
        inpdom.removeAttribute("readonly");
      }, 100);
    };

    //提交库位转移请求
    const moveExpressStorage = () => {
      const params = {
        kst_tag_id: kst_tag_id.value, //库位ID
        kec_express_id: kec_express_id.value, //快递单号
      };
      a_moveExpressStorage(params)
        .then((res) => {
          utils.playAudio(require("@/assets/audio/operation_success.mp3"));
          clickExpressInput(); //清空快递单号

          //非批量模式 清空库位编码
          if (!batch.value) {
            clickTagIdInput();
          }
          utils.success(res.err_msg, 500);
        })
        .catch((err) => {
          clickExpressInput(); //清空快递单号

          //非批量模式 清空库位编码
          if (!batch.value) {
            clickTagIdInput();
          }

          if (err.code === "150016") {
            utils.playAudio(require("@/assets/audio/repeat.mp3"));
          } else {
            utils.playAudio(require("@/assets/audio/error.mp3"));
            utils.fail(err.err_msg, 800);
          }
        });
    };

    onMounted(() => {
      idCacheInputGainFocus();
    });

    return {
      idCache_input_dom,
      get_kst_info,
      kst_info,
      idCache,
      clickTagIdInput,
      moveExpressStorage,
      kst_tag_id,
      kec_express_id,
      clickExpressInput,
      idCacheInputBlur,
      batch,
    };
  },
};
</script>

<style scoped>
.id-cache {
  width: 0;
  height: 0;
  margin-bottom: -15px;
}
</style>
