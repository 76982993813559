/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 01:29:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 09:38:22
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\StockManage\api.js
 */

import http from "@/network/network.js";

// 提交入库
export const cargoEntry = (params) =>
  http.post("/v1/ExpressCargo/CargoEntry", params);

// 提交上架
export const a_toShelves = (params) =>
  http.put("/v1/ExpressCargo/ToShelves", params);

// 获取库位信息
export const getStorageInfoByTagId = (params) =>
  http.get("/v1/Storage/GetStorageInfoByTagId", params, false);

// 提交出库
export const cargoDeliver = (params) =>
  http.put("/v1/ExpressCargo/CargoDeliver", params);

// 提交库位转移
export const a_moveExpressStorage = (params) =>
  http.put("/v1/ExpressCargo/MoveExpressStorage", params);

// 查询快件信息
export const a_queryExpressInfo = (params) =>
  http.get("/v1/ExpressCargo/QueryExpressInfo", params);
